import React from 'react';
import styled from 'styled-components'
import { DesktopNavigation } from '@components/DesktopNavigation';
import { MobileNavigation } from '@components/MobileNavigation';

// Note: industry standard is to have Desktop Nav swap with Mobile Nav @ 992px

export const Header = () => {
  return (
    <div
      data-comp={Header.displayName}
    >
      <DesktopNavWrapper>
        <DesktopNavigation />
      </DesktopNavWrapper>
      
      <MobileNavWrapper>
        <MobileNavigation />
      </MobileNavWrapper>
    </div>
  );
};

Header.displayName = 'Header';

const DesktopNavWrapper = styled.div`
  display: none;
  @media (min-width: 992px) {
    display: block;
  }
`
const MobileNavWrapper = styled.div`
  @media (min-width: 992px) {
    display: none;
  }
`