import React, { useContext } from 'react'
import { Box } from 'theme-ui'

import { UIContext } from '../Layout/contexts/UIContext'

export const Overlay = () => {
  const { uiState, uiDispatch } = useContext(UIContext);

  // destructuring global UI state to extract the piece we care about ("overaly on or off?"):
  const { overlayVisible } = uiState 

  return (
    <Box
      data-comp={Overlay.displayName}
      onClick={() => uiDispatch({ type: 'CLOSE_ALL' })}
      sx={{
        visibility: overlayVisible ? 'visible' : 'hidden',
        position: 'fixed',
        zIndex: 999,
        width: '100%',
        height: '100%',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        bg: 'black',
        transition: 'visibility 0.4s linear, opacity 0.4s linear',
        opacity: overlayVisible ? '0.3' : '0',
      }}
    ></Box>
  )
}

Overlay.displayName = 'Overlay'
