import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components'
import { UnstyledLink } from '@global/UnstyledLink';
import { debounce } from '@utils/debounce';
import { isBrowser } from '@utils/isBrowser';
import { HamburgerSlider } from 'react-animated-burgers'

import { UIContext } from '../Layout/contexts/UIContext'

// source for disappearing reading navbar: https://www.prwhite.io/blog/sticky-navbar-hides-scroll

export const MobileNavigation = () => {
  const { uiState, uiDispatch } = useContext(UIContext)
  const { mobileNavDrawerVisible, navbarIsDark } = uiState;

  const [prevScrollPos, setPrevScrollPos] = useState(0); 
  const [visible, setVisible] = useState(true);  

  const toggleNavDrawer = () => {
    if (mobileNavDrawerVisible) {
      uiDispatch({ type: 'CLOSE_NAV_DRAWER' })
      return
    }
    uiDispatch({ type: 'OPEN_NAV_DRAWER' })
  };

  const handleScroll = debounce(() => {
    if (mobileNavDrawerVisible) {
      // if mobile nav drawer is open we don't care about scroll position:
      return;
    }
    // find current scroll position
    const currentScrollPos = window.pageYOffset;

    if (currentScrollPos > 50) {
      uiDispatch({ type: "DARKEN_NAVBAR" })
    } else {
      uiDispatch({ type: "LIGHTEN_NAVBAR" })
    }
    // if scrolled down from previous, hide navbar, and vice versa. 
    // Also, if you are within 10 pixels of top, show navbar.
    setVisible((prevScrollPos > currentScrollPos && prevScrollPos - currentScrollPos > 20) || currentScrollPos < 10);
    // set state to new scroll position
    setPrevScrollPos(currentScrollPos);
  }, 200);

  useEffect(() => {
    // ensure we are in client, not build
    if (!isBrowser) return null;
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
    
  }, [prevScrollPos, visible, handleScroll]);

  return (
    <Container 
      mobileNavDrawerVisible={mobileNavDrawerVisible}
      visible={visible}
      navbarIsDark={navbarIsDark}
      data-comp={MobileNavigation.displayName}
    >
      <UnstyledLink color={navbarIsDark ? '#121212' : '#FFFFFF'} to='/'>
        <h4 style={{ lineHeight: '0px', margin: 0 }}>Luke Woodard</h4>
      </UnstyledLink>
      <BurgerWrapper >
        <StyledHamburgerSlider
          isActive={mobileNavDrawerVisible}
          mobileNavDrawerVisible={mobileNavDrawerVisible} 
          onClick={toggleNavDrawer} 
          buttonColor="transparent" 
          barColor={navbarIsDark ? '#121212' : '#FFFFFF'}
        />
      </BurgerWrapper>
    </Container>
  );
};

const Container = styled.header`
  display: flex;
  position: fixed;
  top: 0;
  ${({ visible }) =>
    !visible && `top: -80px;`
  }
  transition: top 0.4s;
  z-index: 100;
  padding: 0px 60px;
  height: 100px;
  width: 100%;
  align-items: center;
  background-color: #121212;
  ${({ navbarIsDark }) =>
    navbarIsDark && `background-color: #FFFFFF;`
  }
  color: #FFFFFF;
  // border-bottom: 1px solid #3E415F;
  font-family: 'Gilroy', sans-serif;
  font-weight: bold;
  // border: 1px solid;
  @media(max-width: 814px) {
    padding: 0px 40px;
  }
  @media(max-width: 480px) {
    padding: 0px 20px;
  }
`

const Logo = styled.div`
  transition: color: 0.3s ease-out;
  font-size: 36px;
  font-weight: bold;
`

const BurgerWrapper = styled.div`
  margin-left: auto;
`

const StyledHamburgerSlider = styled(HamburgerSlider)`
  padding: 0px;
  padding-top: 4px;
  outline: none;
  line-height: 0px;
  box-shadow: none;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;

  div {
    width: 24px;
    height: 14px;
  }

  span {
    border-radius: 0px;
    height: 2px;
    width: 24px;
    ${({ mobileNavDrawerVisible }) =>
      mobileNavDrawerVisible &&
      `
    transform: translate3d(0px, 10px, 0px) rotate(45deg) translate(-2px, -3px);
  `}
  }
  span::before {
    border-radius: 0px;
    height: 2px;
    width: 24px;
    top: 6px;
  }
  span::after {
    border-radius: 0px;
    height: 2px;
    width: 24px;
    top: 12px;
    ${({ mobileNavDrawerVisible }) =>
      mobileNavDrawerVisible &&
      `
    top: 1.26rem;
  `}
  }
`

MobileNavigation.displayName = 'MobileNavigation';