import React, { useEffect, useContext } from 'react'
import styled from 'styled-components';
import { useWindowWidth } from '@react-hook/window-size'
import { UnstyledLink } from '@global/UnstyledLink';

import { UIContext } from '../Layout/contexts/UIContext'

export const MobileNavDrawer = () => {
  const { uiState, uiDispatch } = useContext(UIContext)
  const { mobileNavDrawerVisible } = uiState

  const onlyWidth = useWindowWidth();

  const closeDrawer = () => {
    uiDispatch({ type: 'CLOSE_ALL' })
  }

  useEffect(() => {
    if (onlyWidth > 992) {
      uiDispatch({ type: 'CLOSE_ALL' })
    }
  }, [onlyWidth])

  return (
    <div
      style={{
        display: ['block', null, null, 'none'],
        padding: '20px',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        zIndex: 98,
        // top is height of header
        top: '80px',
        bottom: 0,
        left: 0,
        backgroundColor: '#181818',
        // boxShadow: '0px 0px 4px grey',
        transform: mobileNavDrawerVisible
          ? 'translateX(0%)'
          : 'translateX(100%)',
        transition: 'transform 0.2s ease-out',
      }}
      data-comp={MobileNavDrawer.displayName}
    >
      <NavLinksWrapper>
        <UnstyledLink to='/work' onClick={closeDrawer} color='#FFF'><h5>Work</h5></UnstyledLink>
        <UnstyledLink to='/services' onClick={closeDrawer} color='#FFF'><h5>Services</h5></UnstyledLink>
        <UnstyledLink to='/contact' onClick={closeDrawer} color='#FFF'><h5>Contact</h5></UnstyledLink>
      </NavLinksWrapper>
    </div>
  )
}

MobileNavDrawer.displayName = 'MobileNavDrawer'

const NavLinksWrapper = styled.div`
`