import React, { useReducer } from 'react'
import { Box } from 'theme-ui'

import { Header } from '@components/Header'
import { Overlay } from '@components/Overlay'
import { MobileNavDrawer } from '@components/MobileNavDrawer'

import { UIContext } from './contexts/UIContext'
import { uiReducer } from './reducers/uiReducer'

const Layout = ({ children }) => {

  // INITITAL GLOBAL UI STATE //
  const initialUIState = {
    overlayVisible: false,
    mobileNavDrawerVisible: false,
  }

  // ** Note how I rename "dispatch" below to "uiDispatch" to distinguish it from other dispatches coming from other useReducers I might want to create in the future, without having to hurt performance by combining reducers ** //
  const [ uiState, uiDispatch ] = useReducer(uiReducer, initialUIState)

  return (
    <UIContext.Provider value={{ uiState, uiDispatch }}>
      <div
        data-comp={Layout.displayName}
        style={{ fontFamily: 'Gilroy, sans-serif' }}
      >
        <Header />
        <MobileNavDrawer />
        {/* padding top is height of fixed header */}
        <div sx={{ pt: '60px' }}>
          {children}
        </div>
      </div>
      {/* We set main site to 'minHeight: 100vh' and keep <Footer/> outside container so it always stays at bottom */}
      <Overlay />
    </UIContext.Provider>
  )
}

Layout.displayName = 'Layout'
export default Layout
