export const uiReducer = (state, action) => {
  switch(action.type) {
    case 'OPEN_NAV_DRAWER':
      return {
        ...state,
        mobileNavDrawerVisible: true,
        overlayVisible: true,
      }
    case 'CLOSE_NAV_DRAWER':
      return {
        ...state,
        mobileNavDrawerVisible: false,
        overlayVisible: false,
      }
    case 'CLOSE_ALL':
      return {
        ...state,
        cartVisible: false,
        mobileNavDrawerVisible: false,
        overlayVisible: false
      }
    default:
      throw new Error('uiReducer did not pick up an action you dispatched to it, jackass.');
  }
} 