import React from 'react';
import styled from 'styled-components';

import { UnstyledLink } from '@global/UnstyledLink';

export const DesktopNavigation = () => {
  return (
    <Container
      data-comp={DesktopNavigation.displayName}
    >
      <UnstyledLink to='/' color='white'>
        <h3>Luke Woodard</h3>
      </UnstyledLink>
      <NavLinksWrapper>
        <UnstyledLink to='/work' color='#FFF'><h5>Work</h5></UnstyledLink>
        <UnstyledLink to='/services' color='#FFF'><h5>Services</h5></UnstyledLink>
        <UnstyledLink to='/contact' color='#FFF'><h5>Contact</h5></UnstyledLink>
      </NavLinksWrapper>
   </Container>
  );
};

DesktopNavigation.displayName = 'DesktopNavigation';

const Container = styled.div`
  padding: 0px 60px;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
`

const NavLinksWrapper = styled.div`
  margin-left: auto;
  display: flex;

  h5, h6 {
    padding-left: 48.83px;
    cursor: pointer;
    transition: color 100ms linear;
    &:hover {
      color: #08FDD8;
    }
  }
`