import React from 'react';
import { Link } from 'gatsby'

export const UnstyledLink = ({ ...props }) =>
  <Link
    {...props}
    style={{
      textDecoration: 'none',
      color:  props.color ? props.color : 'black'
    }}
  />


// example:
// <UnstyledLink to='/' color='blue'>Some Text or Button</UnstyledLink>