export default {
  colors: {
    text: '#000',
  },
  fonts: {
    body: 'sans-serif',
    heading: 'sans-serif',
  },
  text: {
    heading: {
      normal: {
        fontFamily: 'heading',
        fontWeight: '600',
      }
    }
  },
  styles: {
    root: {
      color: '#000',
      background: '#fff',
      fontFamily: 'sans-serif',
      m: 0,
      p: 0,
    },
  },
  breakpoints: [
    '480px', '600px', '768px', '992px', '1280px', '1440px'
  ],
}